import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/layouts/login'
import { Login } from '../components/login/login'

export default function LoginPage(props: PageProps) {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}
