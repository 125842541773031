import React, { useState } from 'react'
import { navigate } from 'gatsby'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import useIsMounted from 'ismounted'
import {
  Link,
  useFormSubmit,
  LoginIllustration,
  responseError,
} from '../shared'
import { postRequest } from '../../services/api'
import { login } from '../../services/auth'

export const Login = (props) => {
  return (
    <>
      <div className="login-content">
        <div>
          <SVG src="/images/logo-dark.svg" width={126} height={32} />
          <Form />
        </div>
        <div className="text-light">
          ©{new Date().getFullYear()} Heylink™. All rights reserved.
        </div>
      </div>
      <LoginIllustration />
    </>
  )
}

const Form = (props) => {
  const form = useForm()
  const { register, handleSubmit } = form

  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const showError = (message: string, delay: number) => {
    setError(message)
    setTimeout(() => {
      if (isMounted.current) {
        setError('')
      }
    }, delay)
  }

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    const response = await postRequest('/auth/v1/login', {
      userEmail: values.email,
      userPassword: values.password,
    })

    if (response) {
      if (response.code === 200) {
        login(response.data)
        navigate('/')
      } else {
        showError(responseError(response), 10000)
      }
    }
  })

  return (
    <div className="login-email-form">
      <div className="h1 m-b-40">Hi, welcome back!</div>
      {/*<div className="text-light text-bold o-70 m-b-40">
        Lorem ipsum dolor sit amet, emprime dolore nibh velit.
      </div>*/}

      {/*<button className="btn w-100 p-y-4 vertical-middle">
        <SVG src="/images/logo-google.svg" className="m-r-3" />
        <span className="text-dark text-big text-boldest">
          Login with Google
        </span>
      </button>
      <div className="login-email-divider m-y-8">
        <span>or Login with mail</span>
      </div>*/}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="control m-b-8">
          <label>Email</label>
          <input
            type="email"
            placeholder="mail@mailservice.com"
            {...register('email')}
          />
        </div>

        <div className="control m-b-5">
          <label>Password</label>
          <input type="password" {...register('password')} />
        </div>

        <div className="row row-space-between">
          <div className="control control-checkbox">
            <input type="checkbox" {...register('rememberMe')} />
            <label>Remember me</label>
          </div>
          <Link href="/forgot" className="link login-email-forgot">
            Forgot password?
          </Link>
        </div>

        {error && (
          <div className="text-red text-big text-bold m-t-5 m-b--4">
            {error}
          </div>
        )}

        <button
          className="btn btn-primary m-t-40"
          type="submit"
          disabled={submitting}
        >
          Login
        </button>
      </form>

      {/*
      <div className="text-light text-big text-bold m-t-20">
        Don't have an account?{' '}
        <Link href="/signup/" className="link">
          Sign up here
        </Link>
      </div>
      */}
    </div>
  )
}
